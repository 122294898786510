import { StaticImage } from 'gatsby-plugin-image';
import React, { FC, useState } from 'react';
import { Button } from '../ui/Button';

export interface CreaAbbonamentoProps {
  isOpen: boolean;
}

export const CreaAbbonamento: FC<CreaAbbonamentoProps> = ({ isOpen }) => {
  const [infoOpen, setInfoOpen] = useState(isOpen);
  return (
    <div className="container text-center mt-5 mb-5">
      <Button
        disabled={isOpen}
        className="bg-secondary text-white text-center font-rubik pe-3 ps-3 p-2 h3 mb-5"
        onClick={() => setInfoOpen(!infoOpen)}
      >
        SCOPRI COME CREARE IL TUO ABBONAMENTO
      </Button>
      {infoOpen && (
        <StaticImage
          onClick={() => setInfoOpen(!infoOpen)}
          className="mt-5"
          src="./images/infografica.png"
          quality={100}
          alt="infografica"
        />
      )}
    </div>
  );
};
