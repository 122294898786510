import React from 'react';
import { Layout } from '../components/layout';
import { Hero } from '@/components/layout/header/hero';
import { StaticImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby';
import { CreaAbbonamento } from '@/components/home/crea-abbonamento';
import { Divider } from '@/components/ui/Divider';
import { BRAV_LINK } from '@/constants/links';

export default function AbbonamentiEPass() {
  const pageTitle = 'Abbonamenti e Pass';

  const Banner = () => (
    <StaticImage
      src="../images/banners/abbonamenti-e-pass.png"
      alt={pageTitle}
      quality={100}
    />
  );

  const Left = () => (
    <StaticImage
      src="../images/other/abbonamenti-e-pass.png"
      quality={100}
      alt="bicipark"
    />
  );

  const Right = () => (
    <div>
      <div className="mb-3">
        <div>
          <h2 className="h3 text-primary font-rubik">Abbonamenti e Pass</h2>
          <p className="text-secondary font-oxygen mt-3">
            In questa sezione potrai sottoscrivere il tuo abbonamento scegliendo
            la durata che più si adatta alle tue esigenze. Negli stalli gialli è
            possibile sostare solo con abbonamento per residenti, mentre negli
            stalli blu sono attivabili, senza riserva del posto, abbonamenti per
            residenti, domiciliati, lavoratori e ordinari. Nei parcheggi a
            sbarra puoi scegliere tra abbonamenti generici h24 7 giorni su 7
            oppure notturni riservati ai residenti.
          </p>
        </div>
        <div>
          <a href={BRAV_LINK} target="_blank">
            <button className="btn btn-primary mb-3">Abbonati</button>
          </a>
        </div>
      </div>
      <div className="mb-3">
        <div>
          <h2 className="h3 text-primary font-rubik">Orari</h2>
          <p className="text-secondary font-oxygen mt-3">
            Le nostre strutture di sosta sono aperte 7 giorni su 7 e h24.
          </p>
        </div>
        <div>
          <Link to={'/tariffe-e-orari'}>
            <button className="btn btn-primary mb-3">Tariffe e orari</button>
          </Link>
        </div>
      </div>
      <div className="mb-3">
        <div>
          <p className="text-secondary font-oxygen mt-3">
            Sei residente o domiciliato o lavoratore in zona Vicenza fiera?
          </p>
        </div>
        <div>
          <a href={BRAV_LINK} target="_blank">
            <button className="btn btn-primary mb-3">
              Richiedi il tuo pass gratuito
            </button>
          </a>
        </div>
      </div>
    </div>
  );

  return (
    <Layout title={pageTitle}>
      <Hero title={pageTitle} banner={<Banner />} />
      <div className="container mt-5 mb-5">
        <div className="row gx-5">
          <div className="col-12 col-lg-5">
            <Left />
          </div>
          <div className="col-12 col-lg-7 mt-3">
            <Right />
          </div>
        </div>
      </div>
      <Divider size={3} />
      <CreaAbbonamento isOpen={true} />
      <Divider size={5} />
    </Layout>
  );
}
