import React, { FC } from 'react';

interface ButtonProps {
  style?: React.CSSProperties;
  children: React.ReactNode;
  onClick?: () => void;
  className?: string;
  disabled?: boolean;
}

export const Button: FC<ButtonProps> = ({
  style,
  children,
  onClick,
  className,
  disabled,
}) => {
  return (
    <button
      style={style}
      disabled={disabled}
      className={className}
      onClick={onClick}
    >
      {children}
    </button>
  );
};
